import axios from 'axios'

export const compareTexts = async (leftText, rightText) => {
  try {
    const response = await axios.post('http://127.0.0.1:8000/api/diff', {
      left: leftText.split('\n').filter((line) => line.trim() !== ''),
      right: rightText.split('\n').filter((line) => line.trim() !== '')
    })
    return response.data
  } catch (err) {
    throw new Error('Network error or server exception')
  }
}
