<template>
<!--  <nav>-->
<!--    <router-link to="/">Compare</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </nav>-->
  <router-view/>
<!--  <app-footer />-->
</template>

<script>
// import AppFooter from '@/components/AppFooter.vue'
import Compare from '@/views/CompareView.vue'
import About from '@/views/AboutView.vue'

export default {
  name: 'App',
  components: {
    // AppFooter
  }
}
</script>

<style lang="scss">
//#app {
//  font-family: Avenir, Helvetica, Arial, sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  text-align: center;
//  color: #2c3e50;
//  margin-top: 60px;
//}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
  padding-bottom: 50px; /* To avoid content overlap with the footer */
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
