<template>
  <div id="app">
    <div class="language-switcher">
      <select v-model="language" @change="changeLanguage">
        <option value="en">English</option>
        <option value="zh">中文</option>
      </select>
    </div>
    <div class="container">
      <h1>{{ texts.title }}</h1>
      <div class="comparison">
        <div class="column">
          <h2>{{ texts.leftColumn }}</h2>
          <textarea v-model="leftText" :placeholder="texts.leftPlaceholder"></textarea>
        </div>
        <div class="column">
          <h2>{{ texts.rightColumn }}</h2>
          <textarea v-model="rightText" :placeholder="texts.rightPlaceholder"></textarea>
        </div>
      </div>
      <div class="button-group">
        <button @click="compare">{{ texts.compareButton }}</button>
        <button @click="clear">{{ texts.clearButton }}</button>
      </div>
      <div v-if="error" class="error">
        <h3>{{ texts.errorTitle }}</h3>
        <p>{{ error }}</p>
      </div>
      <div v-if="comparisonResult" class="result">
        <h3>{{ texts.resultTitle }}</h3>
        <div class="result-columns">
          <div class="diff-column">
            <h4>{{ texts.leftUnique }}</h4>
            <ul>
              <li v-for="(item, index) in comparisonResult.left_to_right_diff" :key="'left'+index">
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="diff-column">
            <h4>{{ texts.rightUnique }}</h4>
            <ul>
              <li v-for="(item, index) in comparisonResult.right_to_left_diff" :key="'right'+index">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { compareTexts } from '../api/compare'

export default {
  name: 'CompareView',
  setup () {
    const leftText = ref(`toolifyit
it's cool
get method
here is different`)
    const rightText = ref(`post method
toolifyit
websocket
it's cool`)
    const comparisonResult = ref(null)
    const error = ref(null)
    const language = ref('en')
    const texts = ref({
      zh: {
        title: '文本对比工具',
        leftColumn: '左列',
        rightColumn: '右列',
        leftPlaceholder: '请输入左列文本，每行一个项目',
        rightPlaceholder: '请输入右列文本，每行一个项目',
        compareButton: '比较',
        clearButton: '清空',
        errorTitle: '错误：',
        resultTitle: '对比结果：',
        leftUnique: '左列独有：',
        rightUnique: '右列独有：',
        networkError: '网络错误或服务器异常',
        unknownError: '未知错误'
      },
      en: {
        title: 'Text Comparison Tool',
        leftColumn: 'Left Column',
        rightColumn: 'Right Column',
        leftPlaceholder: 'Enter left column text, one item per line',
        rightPlaceholder: 'Enter right column text, one item per line',
        compareButton: 'Compare',
        clearButton: 'Clear',
        errorTitle: 'Error:',
        resultTitle: 'Comparison Result:',
        leftUnique: 'Unique to Left Column:',
        rightUnique: 'Unique to Right Column:',
        networkError: 'Network error or server exception',
        unknownError: 'Unknown error'
      }
    })

    const currentTexts = ref(texts.value[language.value])

    const changeLanguage = () => {
      currentTexts.value = texts.value[language.value]
    }

    const compare = async () => {
      error.value = null
      comparisonResult.value = null

      try {
        const result = await compareTexts(leftText.value, rightText.value)
        if (result.code === 0) {
          comparisonResult.value = result.data
        } else {
          error.value = result.msg || currentTexts.value.unknownError
        }
      } catch (err) {
        console.error('比较失败:', err)
        error.value = currentTexts.value.networkError
      }
    }

    const clear = () => {
      leftText.value = ''
      rightText.value = ''
      comparisonResult.value = null
      error.value = null
    }

    return {
      leftText,
      rightText,
      comparisonResult,
      error,
      compare,
      clear,
      language,
      texts: currentTexts,
      changeLanguage
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
.comparison {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.column {
  flex: 1;
}
textarea {
  width: 100%;
  height: 500px;
  resize: vertical;
}
.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}
button {
  padding: 10px 20px;
  font-size: 16px;
}
.result,
.error {
  margin-top: 20px;
}
.result-columns {
  display: flex;
  gap: 20px;
}
.diff-column {
  flex: 1;
}
ul {
  padding-left: 20px;
}
.error {
  color: red;
}
.language-switcher {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
